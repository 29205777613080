import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
    container: {
        margin: '2rem auto',
        [theme.breakpoints.down('md')]: {
            margin: '1rem auto'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0.5rem 0.2rem',
            padding: 0
        }
    },
    paper: {
        padding: '4rem',
        [theme.breakpoints.down('md')]: {
            padding: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '2rem 1rem'
        }
    },
    title: {
        marginBottom: '2rem',
        textAlign: 'center'
    },
    subtitle: {
        marginTop: '1.5rem',
        marginBottom: '1rem'
    },
    list: {
        fontSize: '1rem'
    }
}));

const Confidentialite = () => {
    const classes = useStyles();
    
    return (
        <Layout>
            <Container maxWidth='lg' className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography variant='h4' className={classes.title}>Cookies Policy</Typography>
                    <Typography variant='subtitle2' gutterBottom><em>September 1, 2022</em></Typography>
                    <Typography>
                        This Cookies Policy (“Cookie Policy”) explains when and why cookies are used by « www.actuleaks.com » on our websites, mobile websites, mobile applications, and any related services (the “Services”).
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>1. What are cookies?</Typography>
                    <Typography gutterBottom>
                        Some User Data is collected on the Services by us and third parties with whom we partner through the use of Cookies. Cookies are small data file identifiers that are transferred to your device or browser that allow us, 
                        and third parties with whom we partner, to recognize your browser or mobile device and transfer information about you and your use of our Services. 
                        For example, Cookies that we use tell us how many times you have visited one of our websites, your search preferences, and if you have clicked on an advertisement that has been displayed to you. 
                    </Typography>
                    <Typography gutterBottom>
                        We (and third parties with whom we partner) may also deliver a file known as a web beacon, pixel or pixel tag to you through our Services. 
                        “Web Beacons” are small, invisible graphic images that may be used on the Services interface or in emails relating to the Services to collect certain information and monitor user activity on the Services interface, 
                        such as to count visits, access Cookies, understand usage effectiveness and to determine if an email has been opened and acted upon. 
                        “Pixels” or “Pixel Tags,” also known as clear GIFs, beacons, spotlight, tags or web bugs, are a method for passing information from the user’s device to a third-party website 
                        (for example, information about a user's browsing behavior, what links they click on, and purchases they have made on the Services). 
                    </Typography>
                    <Typography gutterBottom>
                        We (and third parties with whom we partner) may deliver Web Beacons or Pixel Tags to you through our Services. Web Beacons allow ad networks, marketing partners and other service providers that we use to provide anonymized, 
                        aggregated auditing, research and reporting for us, for advertisers and our other partners. Web Beacons also enable ad networks and other partners to track your behavior for their own analysis and use, 
                        and to serve targeted advertisements to you on the Services or when you visit other websites or platforms. These Web Beacons enable third-party companies to view, edit, or set their own cookies when you visit the Services, 
                        just as if you had visited a web page from their site. 
                    </Typography>
                    <Typography gutterBottom>
                        When we talk about “Cookies” in this Cookie Policy or the Site « actuleaks.com » Privacy Policy, we mean not just cookies, but web beacons, pixel tags, and similar technologies. 
                    </Typography>
                    <Typography gutterBottom>
                        Browsers and devices have tools that allow you to disable cookies on sites and apps. If you disable Cookies you may not be able to access all the features of our services. 
                        We suggest that you keep our Essential Cookies (described below) active for a better user experience and to help us keep improving and developing our products and services. 
                    </Typography>
                    <Typography gutterBottom>
                        For more information on cookies please visit:
                    </Typography>
                    <Typography gutterBottom>
                        Cookie setting in <a target='_blank' rel="noreferrer" href='https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies'>Firefox</a> 
                    </Typography>
                    <Typography gutterBottom>
                        Cookie setting in <a target='_blank' rel="noreferrer" href='https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666'>Chrome</a> 
                    </Typography>
                    <Typography gutterBottom>
                        Cookie setting in <a target='_blank' rel="noreferrer" href='https://support.apple.com/en-gb/guide/safari/sfri11471/mac'>Safari</a> 
                    </Typography>
                    <Typography gutterBottom>
                        Cookie setting in <a target='_blank' rel="noreferrer" href='https://help.opera.com/en/latest/web-preferences/'>Opera</a> 
                    </Typography>
                    <Typography>
                        Cookie setting in <a target='_blank' rel="noreferrer" href='https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10'>Internet Explorer</a> 
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>2. Which types of cookies does « www.actuleaks.com » use?</Typography>
                    <Typography gutterBottom>
                        The categories of Cookies used on the Services are described below. A more detailed list of the specific Cookies being used on each of the Services is available as part of our consent management platform. 
                        For instructions on how to access our consent management platform,, please see below under Can I Change My Cookie Preferences? 
                    </Typography>
                    <Typography variant='h6' gutterBottom>First-Party Cookies</Typography>
                    <Typography gutterBottom>
                        Our Services use our own Cookies, which may be facilitated through use of technologies provided by our third-party vendors (“First-Party Cookies”), to recognize you and remember you as a unique user:
                    </Typography>
                    <Typography variant='h6' gutterBottom>Strictly Necessary or Essential Cookies</Typography>
                    <Typography gutterBottom>
                        These Cookies are essential in order for you to move around the Services and to use the features of the Services. We use “Session Cookies” to remember you during your session on our Services. 
                        They remain in your browser or device until you leave the site and are then deleted from your device once you leave the Services. Session Cookies are used to ensure you are served content correctly, 
                        to balance and monitor load on our servers, and for other technical matters essential to the operation of our Services. You can configure your device to reject these cookies, 
                        but if you do the Services are unlikely to work correctly.
                    </Typography>
                    <Typography gutterBottom>
                        Our consent management platform uses Cookies to record your preferences about our First-Party Cookies and the Third-Party Cookies described below, and to store and share your preferences with relevant third parties. 
                        These Cookies are essential for compliance with the legal obligations imposed on us and on third parties with whom we have a business relationship to ensure we request your consent, 
                        record your consent preferences, and act on such consent preferences.
                    </Typography>
                    <Typography variant='h6' gutterBottom>Functionality Cookies</Typography>
                    <Typography gutterBottom>
                        These cookies remember how you prefer to use the Services and enable us to improve the functionality of the Services. 
                        Functional Cookies are “Persistent Cookies” that remain in your browser or device until you delete them or they reach their expiration date (which may vary depending on the Cookie).
                    </Typography>
                    <Typography gutterBottom>
                        These may, for example, be used to remember your preferences when you use the Services, recognize you when you return to the Services, provide ads, and enhance your experience on the Services. 
                        Use of a Persistent Cookie may be linked to Personal Data while interacting with the Services. If you reject Functional Cookies, you may still use the Services but functionality may be limited in some areas.
                    </Typography>
                    <Typography variant='h6' gutterBottom>Targeting/Advertising Cookies</Typography>
                    <Typography gutterBottom>
                        Targeting Cookies are used to deliver relevant advertisements to users both on and off the Services. We analyze what pages you visit on these sites, what products or services you view, 
                        and whether you view or click on ads or links that are shown to you.
                    </Typography>
                    <Typography gutterBottom>
                        We may also use Cookies to learn whether someone who saw an ad later visited and took an action on the advertiser’s site. 
                        We use this information to send you interest-based ads and may also share this information with third parties as described in our Term and conditions.
                    </Typography>
                    <Typography variant='h6' gutterBottom>Analytics Cookies</Typography>
                    <Typography gutterBottom>
                        Analytics Cookies are used by us and by third parties who process data on our behalf for data analytics purposes 
                        so that we can manage and improve the performance and design of our Services and for monitoring, auditing, research and reporting
                    </Typography>
                    <Typography variant='h6' gutterBottom>Third-Party Cookies</Typography>
                    <Typography gutterBottom>
                        Our advertising partners collect User Data from you for the purposes they disclose through our consent management platform (linked to below under Can I Change My Cookie Preferences?) and their respective privacy policies. 
                        Our advertising partners include ad networks such as third-party ad servers, ad agencies, ad technology vendors and research firms.
                    </Typography>
                    <Typography gutterBottom>
                        Our ad partners use Third-Party Cookies to serve ads to you and to target you with tailored (or personalized) ads when you are using the Services and when you visit third-party sites and platforms. 
                        They also use Cookies to measure traffic and other statistics related to ad campaigns on the Services (for example, to track ad views).
                    </Typography>
                    <Typography gutterBottom>
                        Ad partners and affiliate links partners may view, edit or set their own cookies on your mobile device or web browser when you interact with our Services. 
                        They use Cookies to track your use of our Services and your activities elsewhere online.
                    </Typography>
                    <Typography gutterBottom>
                        We are not responsible for the privacy practices of these third parties and we encourage you to read their privacy policies so that you understand how they process information about you. 
                        We provide links to their privacy policies in our consent management platform, which you can access by clicking on the applicable links below under Can I Change My Cookie Preferences?
                    </Typography>
                    <Typography>
                        For more information on third-party tracking on the Services, please see our Term and conditions.
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>3. Can I Change My Cookie Preferences?</Typography>
                    <Typography gutterBottom>
                        You can decide who you will allow to set Third-Party Cookies on your browser or device by going to our consent management platform by clicking on the Cookie Settings button in the footer of every page on the Services. 
                        Please note that if you intend to opt out of tracking for advertising purposes, you will need to adjust your Cookie Settings separately for each brand.
                    </Typography>
                    <Typography gutterBottom>
                        Certain browser settings may also allow you to choose whether or not to accept cookies or limit certain cookies. Most browsers also provide functionality that lets you review and erase cookies. 
                        You may wish to refer to this guide for information on commonly used browsers. Please be aware that if cookies are disabled, not all features of the Services may operate as intended
                    </Typography>
                    <Typography gutterBottom>
                        Your mobile operating system may let you opt out from having your information collected or used for interest-based advertising on mobile devices. 
                        You should refer to the instructions provided by your mobile device’s manufacturer, but this functionality is usually available in the “Settings” section of your device. 
                        You can also stop us from collecting information via our applications by uninstalling those applications.
                    </Typography>
                    <Typography>
                        Certain web browsers may allow you to enable a “do not track” option that sends signals to the websites you visit indicating that you do not want your online activities tracked. 
                        This is different from blocking cookies as browsers with the “do not track” option selected may still accept cookies. We do not respond to “do not track” signals at this time. 
                        If we do so in the future, we will modify this Cookie Policy accordingly. More information about “do not track” is available at www.allaboutdnt.com.
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>4. Does this Policy Change?</Typography>
                    <Typography gutterBottom>
                        We may change this Cookie Policy at any time. Please take a look at the date at the top of this page to see when this Cookie Policy was last updated. 
                        Any changes in this Cookie Policy will become effective when we make the revised Cookie Policy available on the Services.                        
                    </Typography>
                    <Typography gutterBottom>
                        For more information, please see our Term and conditions.
                    </Typography>
                    <Typography>
                        We hope that this policy helps you understand our use of cookies. If you have any further queries, please contact us at support@actuleaks.com
                    </Typography>
                </Paper>
            </Container>
        </Layout>
    );
};

export default Confidentialite;